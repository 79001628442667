import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Badge,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChatIcon from '@material-ui/icons/Chat';
import AppsIcon from '@material-ui/icons/Apps';
import OrdersIcon from '@material-ui/icons/ShoppingCart';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/Settings';
import { Assignment } from '@material-ui/icons';
import { Avocado, SDK } from "waleed-avocado";

function App() {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("chat");
  const sdkRef = useRef(null);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    if (sdkRef.current) return;

    sdkRef.current = new SDK('123');
    sdkRef.current.onNewMessage = (message) => {
      console.log("New message received", message);
    };
    sdkRef.current.init({ graphqlURI: "hasura.avocad0.dev/v1/graphql", graphqlSecret: "z629AchrY5MGk66yt9eKa", business_id: "c327668d-47dc-4b3c-bcb2-d4f459ebb321" });
  }, []);


  return (
    <div style={{ background: '#CCC' }}>
      <AppBar position="static" style={{ backgroundColor: 'green' }}>
        <Toolbar >
          <IconButton edge="start" color="inherit" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <div style={{ flex: 1, padding: "20px" }}>
            <Typography variant="h6">Avocado Host Core</Typography>
            <Typography variant="subtitle1">
              A sample app to demonstrate SDK view. The <Badge badgeContent={'AVC'} color="secondary">
                <Assignment />
              </Badge> marked are SDK modules.
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer open={open} onClose={handleDrawerToggle}>
        <List>
          <ListItem style={{ width: "250px" }} button onClick={() => handleViewChange("orders")}>
            <ListItemIcon>
              <OrdersIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem>
          <ListItem button onClick={() => handleViewChange("/apps")}>
            <ListItemIcon>
            <Badge badgeContent={'AVC'} color="secondary">
                <AppsIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Apps" />
          </ListItem>
          <ListItem button onClick={() => handleViewChange("/chat")}>
            <ListItemIcon>
              <Badge badgeContent={'AVC'} color="secondary">
                <ChatIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Chats" />
          </ListItem>

          <ListItem button onClick={() => handleViewChange("/settings")}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button onClick={() => handleViewChange("/apps/broadcast")}>
            <ListItemIcon>
            <Badge badgeContent={'AVC'} color="secondary">
                <SendIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Broadcasts" />
          </ListItem>
        </List>
      </Drawer>
      <div style={{ margin: "32px auto 0", width: 1200 }}>
        <Avocado currentScreen={view} sdk={sdkRef.current} />
      </div>
    </div>
  );
}

export default App;
